import GATSBY_COMPILED_MDX from "/Users/touffi/dev/portfolio/data/pages/snippet/home.fr.md";
import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../ui/layout/main/containers/Layout';
import HtmlHeaders from '../../ui/HtmlHeaders';
import {graphql} from 'gatsby';
import Home from '../components/Home';
const HomePage = ({data, location, pageContext, children}) => {
  const {mdx, allGalleries} = data;
  return React.createElement(Layout, {
    lang: pageContext.lang,
    allAlternateLang: pageContext.allAlternateLang
  }, React.createElement(HtmlHeaders, {
    title: mdx.frontmatter.title,
    metaDescription: mdx.frontmatter.metaDescription,
    path: location.pathname,
    allAlternateLang: pageContext.allAlternateLang,
    lang: pageContext.lang
  }), React.createElement(Home, {
    allGalleries: allGalleries.nodes,
    lang: pageContext.lang
  }, children));
};
HomePage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  children: PropTypes.element
};
HomePage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(HomePage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
	query (
		$thumbnailsSrcSetBreakpoints: [Int]
		$thumbnailsSizes: String
		$pageSlug: String
		$langEn: Boolean!
		$langFr: Boolean!
	) {
		mdx(frontmatter: { slug: { eq: $pageSlug } }) {
			frontmatter {
				slug
				title
				metaDescription
			}
		}
		allGalleries: allGalleryJson(sort: { fields: [order], order: ASC }) {
			nodes {
				jsonId
				cover {
					filename {
						childImageSharp {
							gatsbyImageData(
								layout: CONSTRAINED
								height: 400
								width: 400
								breakpoints: $thumbnailsSrcSetBreakpoints
								sizes: $thumbnailsSizes
								placeholder: DOMINANT_COLOR
								transformOptions: {
									fit: COVER
									cropFocus: ATTENTION
								}
							)
						}
					}
				}
				translation {
					en @include(if: $langEn) {
						slug
						title
						metaDescription
						description
					}
					fr @include(if: $langFr) {
						slug
						title
						metaDescription
						description
					}
				}
			}
		}
	}
`;
